import React from "react";
import "./styles.css";
import pdfForm from "../../../assets/form.pdf";
// import logo from "../../../assets/images/logo-new.jpg";
import logo from "../../../assets/images/nfssc-banner2.jpeg";
import { Link, NavLink } from "react-router-dom";
import header1 from "../../../assets/images/HeaderLogo/header1.jpeg";
import header2 from "../../../assets/images/HeaderLogo/header2.jpeg";
import header3 from "../../../assets/images/HeaderLogo/header3.jpeg";
import header4 from "../../../assets/images/HeaderLogo/header4.jpeg";
const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <NavLink to={"/"} className="navbar-brand">
          <img src={logo} alt="" className="img-fluid" />
          {/* <div className="logo-text">

          <h3>Government <span>Approvals</span></h3>
          </div> */}
          <div className="logo-text">
            {/* Added image here */}
            {/* <div className="img-div">
        <img src={header1} className="img-fluid me-2" alt="Logo" /> 
        <img src={header2} className="img-fluid me-2" alt="Logo" /> 
        <img src={header3} className="img-fluid me-2" alt="Logo" />
        <img src={header4} className="img-fluid me-2" alt="Logo" /> 
        </div>
        

        <h3>Government <span>Approvals</span></h3> */}
          </div>
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            fill="#fff"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to={"/"} className="nav-link active" aria-current="page">
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link to={""} className="nav-link dropdown-toggle">
                Courses
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/nfssc-program"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-location-dot"></i>
                    NFSSC Program
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"/university-programme"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-globe"></i>
                    University Program
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                {/* <li>
                  <Link
                    to={"/ncvte-programs"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-pen"></i>
                    NCVTE Program
                  </Link>
                </li> */}
              </ul>
            </li>

            <li className="nav-item dropdown">
              <Link to={""} className="nav-link dropdown-toggle">
                Photo Gallery
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/thrilling-activities"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-compass"></i>
                    Thrilling Activities
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"/awareness-programs"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-layer-group"></i>
                    Awareness Programs
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"/project-by-students"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-calendar"></i>
                    Project By Students
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <Link to={"/our-centers"} className="nav-link" href="#What we do">
                Our Center
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to={"/how-to-apply"}
                className="nav-link"
                href="#What we do"
              >
                How To Apply
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/news"} className="nav-link" href="#What we do">
                News
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to={"/contact-us"} className="nav-link" href="#What we do">
                Contact Us
              </Link>
            </li> */}

            <li className="nav-item dropdown">
              <Link to={""} className="nav-link dropdown-toggle">
                More
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    to={"/about-placement"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-layer-group"></i>
                    About Placement
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to={"/our-team"} className="dropdown-item" href="#">
                    <i class="fa-solid fa-calendar"></i>
                    Our Team
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to={"/placement"} className="dropdown-item" href="#">
                    <i class="fa-solid fa-calendar"></i>
                    Placement
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link
                    to={"/our-qualities"}
                    className="dropdown-item"
                    href="#"
                  >
                    <i class="fa-solid fa-compass"></i>
                    Our Quality
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to={"/result"} className="dropdown-item" href="#">
                    <i class="fa-solid fa-address-card"></i>
                    Result
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <Link to={"/about-us"} className="dropdown-item" href="#">
                    <i class="fa-solid fa-address-card"></i>
                    About Us
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-flex ">
            <a href={pdfForm} className="btn btn-log">
              <i class="fa-solid fa-circle-down mx-1"></i>
              Admission Form
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
