import React from "react";
import Layout from "../Layout";
import "./styles.css";
const ContactUsSection = () => {
  return (
    <Layout>
      <div className="contact-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="contact-title">Contact Us</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="row">
                {/* <div className="col-12 col-md-6">
                  <div className="contact-bg">
                    <div className="container text-center">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="text-white">Contact Details</h3>
                        </div>
                      </div>
                      <div className="row mx-auto">
                        <div className="pt-3"></div>
                        <div className="col-md-6 col-12">
                          <div className="addressone-style text-start">
                            <h5 className="text-white">Admission Office</h5>
                            <div>
                              <div className="address-text">
                                <p>
                                  <i class="fa-solid fa-location-dot"></i>{" "}
                                  <span>
                                    Survey No.27/2,
                                    <br />
                                    Near Globus ITI, Plot No. 104/105
                                  </span>
                                  Kamptee, <br /> Nagpur
                                  <br /> 441001 (MH)
                                </p>
                              </div>
                              <div className="pt-2 text-white">
                                <i class="fa fa-phone"></i>
                                <sapn> (+91) 9420946101</sapn>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="addresstwo-style text-start">
                            <h5 className="text-white">National Campus</h5>
                            <div>
                              <div className="address-text">
                                <p className="text-start">
                                  <i class="fa-solid fa-location-dot"></i>
                                  <span>
                                    {" "}
                                    Service College, S.H – 19,
                                    <br />
                                    Gaygohan, Gangiwara, Umreth Pachmarhi Road,
                                  </span>
                                  Chhindwara – 480441 (MP)
                                </p>
                              </div>
                              <div className="pt-2 text-white">
                                <i class="fa fa-phone"></i>
                                <sapn> (+91) 9827723101</sapn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 col-md-6 mx-auto">
                  <div className="contact-bg">
                    <div className="container text-center">
                      {/* <div className="row">
                        <div className="col-12">
                          <h3 className="text-white">Contact Form</h3>
                        </div>
                      </div> */}
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12 col-md-8 mx-auto">
                              <div className="form-container ">
                                <div className="form-group">
                                  {/* <label>Name</label> */}
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Name"
                                  />
                                </div>
                                <div className="form-group">
                                  {/* <label htmlFor="exampleInputEmail1">
                                    Email
                                  </label> */}
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Your Email"
                                  />
                                </div>
                                <div className="form-group">
                                  {/* <label htmlFor="exampleInputEmail1">
                                    Message
                                  </label> */}
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    rows="4"
                                    cols="50"
                                    placeholder="Enter Some Message"
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="btn btn-log my-3 w-100"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactUsSection;
