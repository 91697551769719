import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./styles.css";
import ourLogo from "../../../assets/images/logo-new.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PlacementSection = () => {
  const [getAllTeamsData, setGetAllTeamsData] = useState([]);
  const [teamDetails, setTeamDetails] = useState({});
  const handleGetTeamsData = async () => {
    try {
      const getTeams = await axios.get(
        "https://backend.nileshdawande.in/api/public/placement"
      );
      if (getTeams?.data?.isDataFound === true) {
        setGetAllTeamsData(getTeams?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  console.log("teamDetails", teamDetails);
  useEffect(() => {
    handleGetTeamsData();
  }, []);

  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Placement</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card my-3 mx-auto" style={{ maxWidth: 450 }}>
                <div className="row g-0">
                  <div className="col-12">
                    <div className="list-main-box text-center">
                      <img
                        src={ourLogo}
                        className="img-fluid my-2 rounded"
                        alt="..."
                      />
                      {getAllTeamsData?.map((value, key) => {
                        return (
                          <>
                            <div className="caption-box">
                              <div className="numbox">{key + 1}</div>
                              <div className="caption-text">
                                <p>{value?.ComanyName}</p>
                              </div>
                              <button
                                className="btn btn-log right-txt"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdrop"
                                onClick={() => setTeamDetails(value)}
                              >
                                More Details
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                More Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="card mb-3">
                <div className="row g-0 align-items-center">
                  {/* <div className="details-img col-md-4">
                    <img
                      src={`https://backend.nileshdawande.in/images/${teamDetails?.Pic}`}
                      // className="img-fluid"
                      alt="..."
                    />
                  </div> */}
                  <div className="col-md-12">
                    <div className="card-body team-details">
                      <h5 className="card-title text-dark">
                        {teamDetails?.ComanyName}
                      </h5>

                      <div className="flex-container">
                        <div> ComanyName :</div>
                        <div>{teamDetails?.ComanyName}</div>
                      </div>
                      <div className="flex-container">
                        <div>Post Name :</div>
                        <div>{teamDetails?.PostName}</div>
                      </div>

                      <div className="flex-container">
                        <div>Comany Address:</div>
                        <div>{teamDetails?.ComanyAddress}</div>
                      </div>
                      <div className="flex-container">
                        <div>ComanyNumber:</div>
                        <div>{teamDetails?.ComanyNumber}</div>
                      </div>
                      <div className="flex-container">
                        <div>NumberOfPost :</div>
                        <div> {teamDetails?.NumberOfPost}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default PlacementSection;
