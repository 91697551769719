import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./styles.css";
import resultImg from "../../../../src/assets/images/nfsc-result.jpeg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment"
const ResultPageSection = () => {
  const [resultId, setResultId] = useState("");
  const [resultTableData, setResultTableData] = useState([]);
  const handleGetResultData = async () => {
    try {
      const getResultData = await axios.get(
        `https://backend.nileshdawande.in/student/result/${resultId}`
      );
      console.log("getResultData", getResultData);
      if (getResultData?.status === 200) {
        setResultTableData(getResultData?.data?.data[0]);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.msg;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.msg;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.msg;
        console.log("message_404", message_404);
        setResultTableData([]);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.msg;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  const handlegetSessionDate = (originalDateString) => {
    // const originalDateString = '2024-02-15T00:00:00.000Z';
    const originalDate = new Date(originalDateString);
    const month = originalDate.getUTCMonth();
    const year = originalDate.getUTCFullYear();
    const mlist = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

    // const month = originalDate.toLocaleString("default", { month: "short" });
    // const year = originalDate.getFullYear();
    // console.log({"month":month,"year":year});
    const formattedDate = `${mlist[month]}. ${year}`;
    // const formattedDate= moment(originalDateString).format("MMM Do YY"); 
    console.log("formattedDate", formattedDate);
    return formattedDate;
  };

  const handlegetMonth = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const diffMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      (endDate.getMonth() - startDate.getMonth());
    return diffMonths;
  };
  const data = [
    {
      subjectcode: "CDCPO 101",
      subjecttitle: "Fire Science And Fire Technology",
      maxmark: "100",
      obtainedmarks: "73",
      result: "pass",
    },
    {
      subjectcode: "CDCPO 102",
      subjecttitle: "Emergency Planning",
      maxmark: "100",
      obtainedmarks: "68",
      result: "pass",
    },
    {
      subjectcode: "CDCPO 103",
      subjecttitle: "Fire Prevention And Protection",
      maxmark: "100",
      obtainedmarks: "73",
      result: "pass",
    },
    {
      subjectcode: "CDCPO 104",
      subjecttitle: "First Aid",
      maxmark: "100",
      obtainedmarks: "67",
      result: "pass",
    },
    {
      subjectcode: "CDCPO 105",
      subjecttitle: "Practical/ Viva",
      maxmark: "100",
      obtainedmarks: "62",
      result: "pass",
    },
  ];
  console.log("resultTableData", resultTableData?.length === 0);

  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Result</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="col-12 col-md-4 mx-auto">
                <div className="cus-result">
                  <div>
                    <div className="mb-3">
                      <label className="form-label text-white">
                        Enter Roll No OR Registration Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={resultId}
                        onChange={(e) => setResultId(e.target.value)}
                      />
                    </div>
                    <div className="result-btn text-center">
                      <button
                        // type="submit"
                        className="btn btn-log"
                        onClick={handleGetResultData}
                      >
                        Show Result
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {resultTableData?.length === 0 ? null : (
            <div className="main-result">
              <div className="row">
                <div className="col-12">
                  <div className="result-img">
                    <img src={resultImg} alt="logo-img" className="img-fluid" />
                  </div>
                </div>
              </div>
              <div className="mx-3">
                <div className="row">
                  <div className="col-12">
                    <div className="row text-center result-table">
                      <div className="col-12 col-md-6">
                        <div className="table-responsive">
                          <table class="table">
                            <tbody>
                              <tr>
                                <td className="fw-bold">Name</td>:
                                <td>
                                  {resultTableData?.student?.FirstName}{" "}
                                  {resultTableData?.student?.LastName}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Father Name</td>:
                                <td>
                                  {resultTableData?.student?.Father}{" "}
                                  {/* {resultTableData?.student?.LastName} */}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Mother Name</td>:
                                <td>
                                  {resultTableData?.student?.Mother}{" "}
                                  {/* {resultTableData?.student?.LastName} */}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Center</td>:
                                <td>
                                  {resultTableData?.center?.Dist}{" "}
                                  {resultTableData?.center?.State}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Program</td>:
                                <td>{resultTableData?.course?.FullName}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td className="fw-bold">Roll No </td>:
                              <td>{resultTableData?.student?.RollNumber}</td>
                            </tr>
                            <tr>
                              <td className="fw-bold">Session</td>:
                              <td>
                                {handlegetSessionDate(
                                  resultTableData?.student?.SessionStart
                                )}{" "}
                                {"TO"}{" "}
                                {handlegetSessionDate(
                                  resultTableData?.student?.SessionEnd
                                )}
                              </td>
                            </tr>
                            {/* <tr>
                <td className="fw-bold">Year/sem</td>:<td>1*</td>
              </tr> */}
                            <tr>
                              <td className="fw-bold">Duration</td>:
                              <td>
                                {resultTableData?.course
                                  ?.CourseDurationMonths === "0"
                                  ? `${resultTableData?.course?.CourseDurationYears} Year`
                                  : `${resultTableData?.course?.CourseDurationMonths} Month`}{" "}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="row text-center result-marks">
                      <div className="col-12">
                        <div class="table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">Subject Code</th>
                                <th scope="col">Subject Title</th>
                                <th scope="col">Max Marks</th>
                                <th scope="col">Obtained Marks</th>
                                <th scope="col">Result</th>
                              </tr>
                            </thead>
                            <tbody>
                              {resultTableData?.subjects &&
                                resultTableData?.subjects?.map((value, key) => {
                                  return (
                                    <tr>
                                      <td>{value?.SubjectCode}</td>
                                      <td>{value?.SubjectFullName}</td>
                                      <td>{value?.SubjectMarks}</td>
                                      <td>{value?.Marks}</td>
                                      <td>{value?.Result}</td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td className="fw-bold">Total</td>
                                <td></td>
                                <td className="fw-bold">
                                  {resultTableData?.subTotal}
                                </td>
                                <td className="fw-bold">
                                  {resultTableData?.student?.total}
                                </td>
                                <td className="fw-bold">
                                  {resultTableData?.resultStatus}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="row text-center result-obtaind-marks">
                      <div className="col-12 obtaind-sub">
                        <div className="table-responsive">
                          <table class="table w-50">
                            <thead>
                              <tr>
                                <th scope="col">Duration</th>
                                <th scope="col">Obtained Marks</th>
                                <th scope="col">Total Marks</th>
                                <th scope="col">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="fw-bold">
                                  {resultTableData?.course
                                    ?.CourseDurationMonths === "0"
                                    ? `12 Month`
                                    : `${resultTableData?.course?.CourseDurationMonths} Month`}
                                </td>
                                <td>{resultTableData?.student?.total}</td>
                                <td>{resultTableData?.subTotal}</td>
                                <td className="fw-bold">
                                  {resultTableData?.resultStatus}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-bold">Grand Total</td>
                                <td>{resultTableData?.student?.total}</td>
                                <td>{resultTableData?.subTotal}</td>
                                <td className="fw-bold">
                                  {(resultTableData?.student?.percentage != null
                                    ? resultTableData.student.percentage.toFixed(2)
                                    : "N/A")}
                                  %
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ToastContainer position="top-right" />
      </div>
    </Layout>
  );
};
export default ResultPageSection;
