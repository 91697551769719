import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./styles.css";
import ourPerson1 from "../../../assets/images/ourPerson1.jpeg";
import ourPerson2 from "../../../assets/images/ourPerson2.jpg";
import ourLogo from "../../../assets/images/ourLogo.png";
import map from "../../../assets/images/map.png";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const OurCentersSection = () => {
  const [getAllCentersData, setGetAllCentersData] = useState([]);
  const [centersDetails, setCentersDetails] = useState({});
  const handleGetCenterData = async () => {
    try {
      const getCenters = await axios.get(
        "https://backend.nileshdawande.in/api/public/center"
      );
      console.log("getCenters", getCenters);
      if (getCenters?.status === 200) {
        setGetAllCentersData(getCenters?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };

  useEffect(() => {
    handleGetCenterData();
  }, []);
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Our Centers</h1>
            </div>
          </div>
          <div className="row">
            <h1 className="ogr-txt-clr my-3">
              {" "}
              {`${getAllCentersData.length}+ Centers in all over india`}
            </h1>
            <div className="row">
              <div className="col-12">
                <div className="row mx-auto">
                  {getAllCentersData?.map((value, key) => {
                    return (
                      <div className="col-md-4 col-12 ">
                        <div className="card custeamCard">
                          <img
                            className="card-img-top teamImg"
                            src={`https://backend.nileshdawande.in/images/${value?.centerImage}`}
                            alt=""
                          />
                          <div className="card-body courseInfo">
                            <div className="team-name">
                              {value?.centerHoName}
                            </div>
                            <h6 className="center-text">{value?.centerName}</h6>
                            <h6 className="center-text">
                              {value?.centerDist} , {value?.centerState}
                            </h6>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-md-6 mx-auto my-3">
              <div className="card cus-infocardd p-3">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="use-imgg my-1">
                      <img src={ourPerson1} alt="user-image1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 bgcard">
                    <div className="use-detailss">
                      <p>CHHINDWARA (MADHYA PRADESH) - NATIONAL CAMPUS</p>
                      <h6>NFSSC- National Fire and Safety Service College</h6>
                      <h6>S.H – 19, Gaygohan, Gangiwada, Umreth, Pachmarhi</h6>
                      <h6>Road, Chhindawara- 480441 (MP)</h6>
                      <h6>Ph: +91-942-094-6101</h6>
                      <h6>JUNAGADH (GUJARAT) - CENTER</h6>
                      <h6>SFA- Somnath Fire Academ</h6>
                      <h6>Junagadh (GJ) – 362220</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mx-auto my-3">
              <div className="card cus-infocard p-3">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="use-imgg my-1">
                      <img src={ourPerson2} alt="user-image1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 bgcard">
                    <div className="use-detailss">
                      <p>CHHINDWARA (MADHYA PRADESH) - NATIONAL CAMPUS</p>
                      <h6>NFSSC- National Fire and Safety Service College</h6>
                      <h6>S.H – 19, Gaygohan, Gangiwada, Umreth, Pachmarhi</h6>
                      <h6>Road, Chhindawara- 480441 (MP)</h6>
                      <h6>Ph: +91-942-094-6101</h6>
                      <h6>JAIPUR (JAIPUR) - CENTER</h6>
                      <h6>SFSC- Sarkar Fire Safety College</h6>
                      <h6>Jaipur (RJ) – 303806</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mx-auto my-3">
              <div className="card cus-infocard p-3">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="use-imgg2 my1">
                      <img src={ourLogo} alt="user-image1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 bgcard">
                    <div className="use-detailss">
                      <p>NAGPUR (MAHARASHTRA) - NATIONAL OFFICE</p>
                      <h6>NFSSC- National Fire and Safety Service College</h6>
                      <h6>
                        NFSSC Headquater, P.N. 168, Near Gurunanak Pharmacy
                      </h6>
                      <h6>College, Uppalwadi, Nagpur – 440026 (MH) </h6>
                      <h6>Ph: +91-942-094-6101</h6>
                      <h6>DIDRUGARH (ASSAM) - NORTH EAST</h6>
                      <h6>NEFSA- North Eastern Fire Service Academy </h6>
                      <h6>Dibrugarh, ASSAM -07</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <section className="map-bg ">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="cus-map">
                  <img src={map} className="img-fluid" alt="map-img" />
                </div>
              </div>
              <div className="col-md-6 col-12 my-2">
                <h1 className="ogr-txt-clr text-center"> Our Centers</h1>

                <div className="list-main-box">
                  {getAllCentersData?.map((value, key) => {
                    return (
                      <>
                        <div className="caption-box">
                          <div className="numbox">{key + 1}</div>
                          <div className="caption-text">
                            <p>{value?.centerState}</p>
                          </div>
                          {/* <button
                            className="btn btn-log center-txt"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            onClick={() => setCentersDetails(value)}
                          >
                            More Details
                          </button> */}
                        </div>
                      </>
                    );
                  })}

                  {/* <div className="caption-box">
                    <div className="numbox">02</div>
                    <div className="caption-text">
                      <p>Goa</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">03</div>
                    <div className="caption-text">
                      <p>Madhya Pradesh</p>
                    </div>
                  </div>

                  <div className="caption-box">
                    <div className="numbox">04</div>
                    <div className="caption-text">
                      <p>Chhattisgarh</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">05</div>
                    <div className="caption-text">
                      <p>Uttar Pradesh</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">06</div>
                    <div className="caption-text">
                      <p>Rajasthan</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">07</div>
                    <div className="caption-text">
                      <p>Panjab</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">08</div>
                    <div className="caption-text">
                      <p>Haryana</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">09</div>
                    <div className="caption-text">
                      <p>West Bengal</p>
                    </div>
                  </div>
                  <div className="caption-box">
                    <div className="numbox">10</div>
                    <div className="caption-text">
                      <p>Gujarat</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                More Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="card mb-3">
                <div className="row g-0 align-items-center">
                  <div className="details-img col-md-4">
                    <img
                      src={`https://backend.nileshdawande.in/images/${centersDetails?.centerImage}`}
                      // className="img-fluid"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body team-details">
                      <h5 className="card-title text-dark">
                        {centersDetails?.centerState}
                      </h5>
                      <div className="flex-container">
                        <div>Center Host Name :</div>
                        <div>{centersDetails?.centerHoName}</div>
                      </div>
                      {/* <div className="flex-container">
                        <div>Mobile Number:</div>
                        <div>{teamDetails?.Designation}</div>
                      </div> */}
                      <div className="flex-container">
                        <div>Center Name :</div>
                        <div>{centersDetails?.centerName}</div>
                      </div>
                      <div className="flex-container">
                        <div>center Dist :</div>
                        <div>{centersDetails?.centerDist}</div>
                      </div>
                      <div className="flex-container">
                        <div>centerState :</div>
                        <div>{centersDetails?.centerState}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default OurCentersSection;
