import React from "react";
import Layout from "../Layout";
import "./styles.css";
const NcvtePageSection = () => {
  const data = [
    {
      SectorCode: "FIR -642",
      Course: "Certificate in Assistant Fire Operator",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -643",
      Course: "Certificate in Fire and Rescue Operator",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -644",
      Course: "Certificate in Fire Technician",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -645",
      Course: "Certificate in fire & Safety Engineering Techniques",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -646",
      Course: "	Certificate in Industrial Safety Engineering",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -647",
      Course: "	Certificate in Training in Construction Safety",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -648",
      Course: "	Certificate in On-site and Off –site emergency Plans",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -649",
      Course: "	Certificate in Disaster Management and Control",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -650",
      Course: "	Certificate in Accident Investigation",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
    {
      SectorCode: "FIR -646",
      Course: "		Certificate in First-Aid and Basic Life Support",
      Eligibility: "8 th pass",
      Duration: "6  Months",
    },
  ];

  const subData = [
    {
      SectorCode: "FIR -652",
      Course: "Diploma in Construction Safety Management",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -653",
      Course: "Diploma in Fire & Industrial Safety Management",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -654",
      Course: "Diploma in Industrial Safety",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -655",
      Course: "Diploma in Fire Man Technician",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -656",
      Course: "Diploma in Health , safety & Environment Management",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -657",
      Course: "Diploma in fire & Safety Engineering Techniques",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -658",
      Course: "Diploma in Health ,Environment & Safety Engineering",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -659",
      Course: "Diploma in Industrial Environment Safety",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -660",
      Course: "Diploma in Environment Safety Engineering",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -661",
      Course: "Diploma in Electrical Safety",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -662",
      Course: "Diploma in Oil Exploration and Production",
      Eligibility: "10th pass",
      Duration: "1 year",
    },

    {
      SectorCode: "FIR -663",
      Course: "	Diploma in Refinery installation and Configuration",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -664",
      Course: "Diploma in Hazard and Risk",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -665",
      Course: "Diploma in Accident Prevention and Cost",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -666",
      Course: "Diploma in Material Handling Safety – manual and Mechanical",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -667",
      Course: "Diploma in Fire & Safety Management",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -668",
      Course: "Diploma in Confined space Hazards and Sage Practices",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -669",
      Course: "Diploma in Fire and Explosion Hazards in oil and Gas",
      Eligibility: "10th pass",
      Duration: "1 year",
    },
    {
      SectorCode: "FIR -670",
      Course: "Diploma in Construction safety Management",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -671",
      Course: "	Diploma IN Fire & industrial Safety Management",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -672",
      Course: "Diploma in Industrial Safety",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -673",
      Course: "	Diploma in Fire Man Technician",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -674",
      Course: "Diploma in Health , Safety & Environment Management",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -675",
      Course: "Diploma in Fire & Safety Engineering Techniques",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -676",
      Course: "Diploma in Health , Environment & Safety Engineering",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -677",
      Course: "	Diploma in Industrial Environmental Safety",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -678",
      Course: "	Diploma in Environment Safety Engineering",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -679",
      Course: "Diploma in Electrical Safety",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -680",
      Course: "	Diploma in Oil Exploration and Production",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -681",
      Course: "Diploma in Refinery installation and Configuration",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -682",
      Course: "Diploma in Hazard and Risk",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -683",
      Course: "Diploma in Accident Prevention and Cos",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -684",
      Course: "Diploma in Material Handling Safety – Mechanical",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -685",
      Course: "	Diploma in Fire & Safety Management",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -686",
      Course: "Diploma in Confined Space Hazards and",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
    {
      SectorCode: "FIR -687",
      Course: "Diploma in Fire Explosion Hazard in oil and Gas",
      Eligibility: "10th pass",
      Duration: "2 year",
    },
  ];

  return (
    <Layout>
      <div className="contact-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="contact-title">NCVTE Program</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div class="table-responsive">
                <table class="table cus-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">SectorCode</th>
                      <th scope="col">Course</th>
                      <th scope="col">Eligibility</th>
                      <th scope="col">Duration</th>
                    </tr>
                  </thead>
                  <tbody className="cus-tbody">
                    {data.map((value, key) => {
                      return (
                        <tr>
                          <td scope="row">{key + 1}</td>
                          <td>{value.SectorCode}</td>
                          <td>{value.Course}</td>
                          <td>{value.Eligibility}</td>
                          <td>{value.Duration}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div class="table-responsive">
                <table class="table cus-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">SectorCode</th>
                      <th scope="col">Course</th>
                      <th scope="col">Eligibility</th>
                      <th scope="col">Duration</th>
                    </tr>
                  </thead>
                  <tbody className="cus-tbody">
                    {subData.map((value, key) => {
                      return (
                        <tr>
                          <td scope="row">{key + 1}</td>
                          <td>{value.SectorCode}</td>
                          <td>{value.Course}</td>
                          <td>{value.Eligibility}</td>
                          <td>{value.Duration}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default NcvtePageSection;
