import React from "react";
import Layout from "../Layout";
import "./styles.css";

const HowtoApplySection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">How to Apply/Downloads</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card cus-applybox">
                <div className="card-body">
                  <h5>ADMISSION PROCEDURE – NATIONAL CAMPUS, NAGPUR (MS)</h5>
                  <div className="cus-applydivone">
                    <div className="firsapplydiv">
                      <p>Download Application Form & Fill the Correct Detail</p>
                      <p>
                        Demand Draft drawn in favor of National Fire & Safety
                        Service College, payable at Nagpur (Maharashtra) Or
                        Submitted to Cash into NFSSC Account towards the
                        prescribed admission fees.
                      </p>
                      <p>
                        Mail us to Your Application Scan Copy with required
                        documents and DD/Payment Slip on- info@nfsscindia.com
                        for your registration
                      </p>
                      <p>
                        Send Hard Copy with Required Documents by Courier to
                        Following Address
                      </p>
                      <p>
                        NFSSC HOUSE, P.N. 168, Near gurunanak Pharmacy College,
                        Uppalwadi, Nagpur – 440026 (MH)
                        <br />
                        <span>Phone Number : 9827723101</span>
                        {/* <br />
                        <span>Phone Number : 9420946101</span> */}
                      </p>

                      <p>
                        If Any Query, Call to Student Helpline Numbers
                        <br />
                        <span>Phone Number : +91- 982-7723-101</span>
                        {/* <br />
                        <span>Phone Number : +91-942-0946-101</span> */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card cus-applybox">
                <div className="card-body">
                  <h5>ADMISSION PROCEDURE – NFSSC CENTER’S</h5>
                  <div className="cus-applydivone">
                    <div className="firsapplydiv">
                      <p>Call To Respective Centre’s Phone Numbers</p>
                      <p>Download Application Form & Fill the Correct Detail</p>
                      <p>
                        E-Mail to Your Application Scan Copy with required
                        documents and DD/Payment Slip on Respective Centre’s
                        E-Mail ID
                      </p>
                      <p>
                        Send Hard Copy with Required Documents To Respective
                        Centre’s Address
                      </p>
                      <p>
                        If Any Query, Call to Respective Centre’s Co-Ordinator
                        Phone Numbers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card cus-applybox">
                <div className="card-body">
                  <h5>For Diploma & BSc Programme</h5>
                  <div className="cus-applydivone">
                    <div className="firsapplydiv">
                      <p>10 Color Photo</p>
                      <p>ID Proof & Address Proof- 2 Set of Photo Copy</p>
                      <p>10 th Mark sheet OR Diploma- 2 Set of Photo Copy</p>
                      <p>
                        12 (10+2) Mark sheet OR Diploma- 2 Set of Photo Copy
                      </p>
                      <p>Medical Fitness Certificate (MBBS Doctor Certified)</p>

                      <p>Caste Certificate 2 Set of Photo Copy</p>
                      <p>Leaving Certificate (Original)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="card cus-applybox">
                <div className="card-body">
                  <h5>For PG-Diploma & MBA Programme</h5>
                  <div className="cus-applydivone">
                    <div className="firsapplydiv">
                      <p>10 Color Photo</p>
                      <p>ID Proof & Address Proof- 2 Set of Photo Copy</p>
                      <p>10 th Mark sheet OR Diploma- 4 set of Photo Copy</p>
                      <p>
                        12 (10+2) Mark sheet OR Diploma- 4 set of Photo Copy
                      </p>
                      <p>
                        Degree (Graduation) 3/4 yr Mark sheet OR Provisional OR
                      </p>
                      <p>Degree Certificate- 2 Set of Photo Copy</p>
                      <p>Medical Fitness Certificate (MBBS Doctor Certified)</p>
                      <p>Caste Certificate- 2 Set of Photo Copy</p>
                      <p>Leaving Certificate (Original)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default HowtoApplySection;
