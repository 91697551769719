import React from "react";
import Layout from "../Layout";
import "./styles.css";
import AP1 from "../../../assets/images/Awareness Programs/AP1.jpeg";
import AP2 from "../../../assets/images/Awareness Programs/AP2.jpeg";
import AP3 from "../../../assets/images/Awareness Programs/AP3.jpeg";
import AP4 from "../../../assets/images/Awareness Programs/AP4.jpg";
import AP5 from "../../../assets/images/Awareness Programs/AP5.jpg";
import AP6 from "../../../assets/images/Awareness Programs/AP6.jpg";
import AP7 from "../../../assets/images/Awareness Programs/AP7.jpg";
import AP8 from "../../../assets/images/Awareness Programs/AP8.jpg";
import AP9 from "../../../assets/images/Awareness Programs/AP9.jpg";
import AP10 from "../../../assets/images/Awareness Programs/AP10.jpg";
import AP11 from "../../../assets/images/Awareness Programs/AP11.jpg";
import AP12 from "../../../assets/images/Awareness Programs/AP12.jpg";
import AP13 from "../../../assets/images/Awareness Programs/AP13.jpg";
import AP14 from "../../../assets/images/Awareness Programs/AP14.jpg";
import AP15 from "../../../assets/images/Awareness Programs/AP15.jpg";
import AP16 from "../../../assets/images/Awareness Programs/AP16.jpg";
import AP17 from "../../../assets/images/Awareness Programs/AP17.jpg";
import AP18 from "../../../assets/images/Awareness Programs/AP18.jpeg";

const AwarenessProgramsSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Awareness Programs</h1>
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="row my-4">
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP1} class="card-img-top" alt="..." />
                  {/* <h4 className="mt-2">Diploma In Sub-Fire Officer</h4> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP2} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Hazards & Risk</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP3} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Safety Management</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP4} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP5} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fireman Tanning</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP6} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP7} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP8} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP9} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP10} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP11} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP12} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP13} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP14} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP15} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP16} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP17} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-awareness p-2"
                  data-aos="flip-left"
                >
                  <img src={AP18} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default AwarenessProgramsSection;
