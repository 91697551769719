import React, { useState } from "react";
import Layout from "../Layout";
import "./styles.css";
import JobLogo from "../../../assets/images/job_assistance.png";
import Person1 from "../../../assets/images/person1.jpg";
import Person2 from "../../../assets/images/Person2.jpg";
import infoperson1 from "../../../assets/images/manbg1.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AboutPlacementSection = () => {
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("document", file);

    try {
      const response = await axios.post(
        "https://backend.nileshdawande.in/api/send-mail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response?.data?.status === true) {
        toast.success(response?.data?.msg);
      }
      console.log(response.data);
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 413) {
        const message_413 = error?.response?.data?.message;
        console.log("message_404", message_413);
        toast.error(message_413);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const newsData = [
    {
      description: "Beginner’s Guide to Fire Protection Systems",
    },
    {
      description:
        "What is Included in an Industrial Fire Protection Inspection?",
    },
    {
      description:
        "Safeguarding Properties in Winter: Fire System Inspection and Testing",
    },
    {
      description:
        "Fire Protection for Lithium-ion Battery Storage and Manufacturing",
    },
    {
      description: "Improve Your Evacuation Plan",
    },
    {
      description: "Cleaning Your Commercial Kitchen Hood Suppression System",
    },
  ];
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">About Placement</h1>
            </div>
          </div>

          <div className="row my-5">
            <h1 className="ogr-txt-clr my-3">Our Highest Package</h1>
            <div className="col-12 col-md-6">
              <div className="card cus-ass-card p-3">
                <div className="row align-items-center">
                  <div className="col-md-12 col-12">
                    <div className="user-img">
                      <img
                        className="card-img-top"
                        src={JobLogo}
                        alt="Card image cap"
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                    <div className="caption">
                      <p>Highest Package IN ABROAD</p>
                      <p>Rs. 25,00,000/- P.A</p>
                      <p>200 + Students Earning Salary</p>
                      <p>Rs. 15,00,000/- P.A</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="caption">
                      <p>Highest Package IN ABROAD</p>
                      <p>Rs. 25,00,000/- P.A</p>
                      <p>500 + Students Earning Salary</p>
                      <p>Rs. 8,00,000/- P.A</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* 2 */}
            <div className="col-12 col-md-6">
              <div className="card cus-ass-card p-3">
                <div className="row align-items-center">
                  <div className="col-md-12 col-12">
                    <div className="user-img">
                      <img
                        className="card-img-top"
                        src={JobLogo}
                        alt="Card image cap"
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-6">
                    <div className="caption">
                      <p>Highest Package IN INDIA</p>
                      <p>Rs. 10,00,000/- P.A</p>
                      <p>700 + Students Earning Salary</p>
                      <p>Rs. 5,00,000/- P.A</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="caption">
                      <p>Highest Package IN INDIA</p>
                      <p>Rs. 10,00,000/- P.A</p>
                      <p>1000 + Students Earning Salary</p>
                      <p>Rs. 2,50,000/- P.A</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <h1 className="ogr-txt-clr my-3"> Our Successful Candidate</h1>
            <div className="col-12 col-md-6 mx-auto">
              <div className="card cus-infocard p-3">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="use-img">
                      <img src={Person1} alt="user-image1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 bgcard">
                    <div className="use-details text-center">
                      <h4>Lakhan Chouhan</h4>
                      <h5>NFSSC</h5>
                      <p>
                        I had a Great Experience at NFSSC, I got Best Traning
                        and Placement Support.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mx-auto">
              <div className="card cus-infocard p-3">
                <div className="row">
                  <div className="col-md-12 col-12">
                    <div className="use-img">
                      <img src={Person2} alt="user-image1" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-12 bgcard">
                    <div className="use-details text-center">
                      <h4>Satish Vishwkarma</h4>
                      <h5>NFSSC</h5>
                      <p>
                        I had a Great Experience at NFSSC, I got Best Traning
                        and Placement Support.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-md-6 col-12">
              <h1 className="ogr-txt-clr text-center my-3"> Doucument Form</h1>
              <div className="row">
                <div className="card form-document-bg py-4 px-5">
                  <div className="row">
                    <div className="col-12 col-md-10 mx-auto">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label className="form-label text-light fw-bold">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label className="form-label text-light fw-bold my-2">
                            Upload Your Resume
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            onChange={(e) => setFile(e.target.files[0])}
                            accept="*"
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn text-center btn-log"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="news">
                <h1 className="ogr-txt-clr text-center my-3"> News</h1>
                <div className="card form-document-bg py-4 ">
                  <marquee
                    direction="up"
                    onMouseOut={(e) => e.currentTarget.start()}
                    onMouseOver={(e) => e.currentTarget.stop()}
                    scrollamount="2"
                    scrolldelay="90"
                  >
                    {newsData.map((data, key) => {
                      return (
                        <div className="cus-new">
                          <div className="numbox">{key + 1}</div>
                          <div className="caption-text">
                            <p>{data?.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </marquee>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-md-6 col-12">
              <h1 className="ogr-txt-clr text-center"> JOB OPPORTUNITY</h1>

              <div className="list-main-box">
                <div className="caption-box">
                  <div className="numbox">01</div>
                  <div className="caption-text">
                    <p>Health Officer</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">02</div>
                  <div className="caption-text">
                    <p>Safety HOD</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">03</div>
                  <div className="caption-text">
                    <p>Health Instructor</p>
                  </div>
                </div>

                <div className="caption-box">
                  <div className="numbox">04</div>
                  <div className="caption-text">
                    <p>Fire-Safety Advisor</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">05</div>
                  <div className="caption-text">
                    <p>Fire Technician</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">06</div>
                  <div className="caption-text">
                    <p>Fire Officer</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">07</div>
                  <div className="caption-text">
                    <p>Safety Officer</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">08</div>
                  <div className="caption-text">
                    <p>HSE Officer</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">09</div>
                  <div className="caption-text">
                    <p>Safety Engineer</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">10</div>
                  <div className="caption-text">
                    <p>Fire Supervisor</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">11</div>
                  <div className="caption-text">
                    <p>Safety Supervisor</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-12">
              <h1 className="ogr-txt-clr text-center"> SCOPE AND DEMAND</h1>

              <div className="list-main-box">
                <div className="caption-box">
                  <div className="numbox">01</div>
                  <div className="caption-text">
                    <p>Steel Plant</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">02</div>
                  <div className="caption-text">
                    <p>Construction Company</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">03</div>
                  <div className="caption-text">
                    <p>Electronic Electrical Company</p>
                  </div>
                </div>

                <div className="caption-box">
                  <div className="numbox">04</div>
                  <div className="caption-text">
                    <p>Plastic Polymer Company</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">05</div>
                  <div className="caption-text">
                    <p>Automobile Company</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">06</div>
                  <div className="caption-text">
                    <p>Paper Industry</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">07</div>
                  <div className="caption-text">
                    <p>Food Industry</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">08</div>
                  <div className="caption-text">
                    <p>Oil industry</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">09</div>
                  <div className="caption-text">
                    <p>Refineries</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">10</div>
                  <div className="caption-text">
                    <p>Cargo</p>
                  </div>
                </div>
                <div className="caption-box">
                  <div className="numbox">11</div>
                  <div className="caption-text">
                    <p>Shipping Corporation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};
export default AboutPlacementSection;
