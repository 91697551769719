import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import "./styles.css";
import strength1 from "../../../assets/images/approve.jpeg";
import strength2 from "../../../assets/images/strength2.jpg";
import strength3 from "../../../assets/images/strength3.jpg";
import Courses1 from "../../../assets/images/Courses1.jpg";
import About from "../../../assets/images/aboutus.jpeg";
import Vision from "../../../assets/images/vision.jpeg";
import Mission from "../../../assets/images/mission.jpeg";

import Youtub from "../../../assets/images/youtub.jpeg";
import Facebook from "../../../assets/images/facebook.jpeg";
import Instagram from "../../../assets/images/instagram.jpeg";

import Courses2 from "../../../assets/images/Courses2.jpg";
import Courses3 from "../../../assets/images/Courses3.jpg";
import Courses4 from "../../../assets/images/Courses4.jpg";
import Courses5 from "../../../assets/images/Courses5.jpg";
import Courses6 from "../../../assets/images/Courses6.jpg";
// import bannerimg from "../../../assets/images/nfssc-banner.jpg";
import bannerimg from "../../../assets/images/newbanner.jpeg";

import BannerImg1 from "../../../assets/images/Facilities/bannerimg1.jpeg";
import BannerImg2 from "../../../assets/images/Facilities/bannerimg2.jpeg";

import Adventure from "../../../assets/images/Banner/adventure.jpeg";
import Gym from "../../../assets/images/Banner/gym.jpeg";
import Hostel from "../../../assets/images/Banner/hostel.jpeg";
import swimming from "../../../assets/images/Banner/swimming.jpeg";

import nfsscVideo from "../../../assets/video/nfsscvideo.mp4";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
const LandingPageSection = () => {
  const navigate = useNavigate();
  const [getCounter, setCounter] = useState(0);
  const handleGetNewsData = async () => {
    try {
      const getnoCounter = await axios.get(
        "https://backend.nileshdawande.in/api/counter/get-counter"
      );
      console.log("getnoCounter", getnoCounter);
      if (getnoCounter?.data?.isCounter === true) {
        setCounter(getnoCounter?.data?.data?.Counter);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  console.log("getCounter", getCounter);
  useEffect(() => {
    handleGetNewsData();
  }, []);
  return (
    <Layout>
      <>
        <section className="our-m">
          <div className="container-fluid">
            <div
              id="carouselExampleDark"
              className="carousel carousel-dark slide py-2"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-slide-to={3}
                  aria-label="Slide 4"
                />
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval={10000}>
                  <img src={Hostel} className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <h5>Hostel</h5>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval={3000}>
                  <img src={Adventure} className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <h5>Adventure</h5>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={Gym} className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <h5>Gym</h5>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={swimming} className="d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <h5>Swimming</h5>
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleDark"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="container text-center">
            {/* <div className="col-12">
              <div className="row">
                <div className="col-6 mb-2">
                  <h1 className="ogr-txt-clr">Our Facilities</h1>
                </div>
              </div>
            </div> */}

            <div className="row py-3">
              <div className="col-12">
                <div className="marqueetag">
                  {/* marqueetag */}
                  <marquee>
                    Notice :- Website is under maintenance for any query please
                    contact 9420946101
                  </marquee>
                </div>
              </div>
            </div>

            <div className="row py-3">
              <div className="col-12 my-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h1
                      className="ogr-txt-clr text-center"
                      data-aos="fade-left"
                    >
                      Our Strength
                    </h1>
                  </div>
                </div>
                <div className="row my-4" data-aos="fade-right">
                  {/* <div className="col-md-4 col-10 mb-3 mx-auto">
                    <div className="card  cus-card p-2 " data-aos="flip-left">
                      <img
                        src={strength1}
                        class="card-img-top strength-img"
                        alt="..."
                      />
                      <h2 className="mt-2">Approval</h2>
                      <div className="card-body">
                        <div className="my-2">
                          <Link to={"/approve"} className="btn cus-btn">
                            View more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-10 mx-auto mb-3">
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={strength2}
                        class="card-img-top strength-img"
                        alt="..."
                      />
                      <h2 className="mt-2">About Placement </h2>
                      <div className="card-body">
                       
                        <div className="my-2">
                          <Link to={"/about-placement"} className="btn cus-btn">
                            View more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-10 mx-auto mb-3">
                    <div className="card cus-card p-2">
                      <img
                        src={strength3}
                        class="card-img-top strength-img"
                        alt="..."
                      />
                      <h2 className="mt-2">Director & Supporting Staff</h2>
                      <div className="card-body">
                    
                        <div className="my-2">
                          <Link to={"/our-team"} className="btn cus-btn">
                            View more
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div id="cards_landscape_wrap-2">
                    <div className="container">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                          <a href>
                            <div className="card-flyer">
                              <div className="text-box">
                                <div className="image-box">
                                  <img src={strength1} alt="IMG" />
                                </div>
                                <div className="text-container">
                                  <h6>Approval</h6>
                                </div>
                              </div>
                              <div
                                className="card-footer cus-footer-btn"
                                onClick={() => {
                                  navigate("/approve");
                                }}
                              >
                                <button>View More</button>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                          <a href>
                            <div className="card-flyer">
                              <div className="text-box">
                                <div className="image-box">
                                  <img src={strength2} alt="IMG" />
                                </div>
                                <div className="text-container">
                                  <h6>About Placement</h6>
                                </div>
                              </div>
                              <div
                                className="card-footer cus-footer-btn"
                                onClick={() => {
                                  navigate("/about-placement");
                                }}
                              >
                                <button>View More</button>
                              </div>
                            </div>
                          </a>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                          <a href>
                            <div className="card-flyer">
                              <div className="text-box">
                                <div className="image-box">
                                  <img src={strength3} alt="img" />
                                </div>
                                <div className="text-container">
                                  {/* <h6>Director & Supporting Staff</h6> */}
                                  <h6> Supporting Staff</h6>
                                </div>
                              </div>
                              <div
                                className="card-footer cus-footer-btn"
                                onClick={() => {
                                  navigate("/our-team");
                                }}
                              >
                                <button>View More</button>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 my-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h1 className="ogr-txt-clr text-center">Important Links</h1>
                  </div>
                </div>
                <div className="row my-4">
                  <div
                    className="col-md-4 col-6 mb-3"
                    onClick={() => navigate("/about-us")}
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={About}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">About-us</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-6 mb-3"
                    onClick={() => navigate("/ourmission")}
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={Mission}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">Our Mission</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-6 mb-3"
                    onClick={() => navigate("/ourvision")}
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={Vision}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">Our Vision</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-6 mb-3"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/NFSSCINDIA?mibextid=ZbWKwL",
                        "_blank"
                      )
                    }
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={Facebook}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">Facebook</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-6 mb-2"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/@nfsscindia5034",
                        "_blank"
                      )
                    }
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={Youtub}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">YouTub</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-6 mb-2"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/nfssc_india/?utm_source=qr&igsh=Z3N1cjl4cHN3Yno0",
                        "_blank"
                      )
                    }
                  >
                    <div className="card cus-card p-2" data-aos="flip-left">
                      <img
                        src={Instagram}
                        class="card-img-top cus-image"
                        alt="..."
                      />
                      <div class="cus-middle">
                        <div class="cus-text">Instagram</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 my-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h1 className="ogr-txt-clr text-center">NFSSC Video</h1>
                  </div>
                </div>
                <div className="row my-4">
                  <div className="col-12 ">
                    <div className="">
                      <video className="fullscreen" autoPlay muted loop>
                        <source src={nfsscVideo} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container py-5">
            <div className="row">
              <div className="col-md-3 col-6">
                <div className="counter">
                  <div className="counter-icon">
                    <i className="fa fa-briefcase" />
                  </div>
                  <h3>VISITORS</h3>
                  <span className="counter-value">{getCounter}+</span>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="counter orange">
                  <div className="counter-icon">
                    <i className="fa fa-globe" />
                  </div>
                  <h3>AWARDS WINNING</h3>
                  <span className="counter-value">25+</span>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="counter blue">
                  <div className="counter-icon">
                    <i className="fa fa-briefcase" />
                  </div>
                  <h3>OUR CLIENT</h3>
                  <span className="counter-value">50+</span>
                </div>
              </div>
              <div className="col-md-3 col-6">
                <div className="counter red">
                  <div className="counter-icon">
                    <i className="fa fa-globe" />
                  </div>
                  <h3>OUR CENTERS</h3>
                  <span className="counter-value">5+</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <ToastContainer position="top-right" />
    </Layout>
  );
};

export default LandingPageSection;
