import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.css";
const NewsPageSection = () => {
  const [getAllNewsData, setGetAllNewsData] = useState([]);
  const handleGetNewsData = async () => {
    try {
      const getNews = await axios.get(
        "https://backend.nileshdawande.in/api/admin/get-news"
      );
      if (getNews?.data?.isNewsFound === true) {
        setGetAllNewsData(getNews?.data?.data);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const message_400 = error?.response?.data?.message;
        toast.error(message_400);
      } else if (error.response.status === 401) {
        const message_401 = error?.response?.data?.message;
        toast.error(message_401);
      } else if (error.response.status === 404) {
        const message_404 = error?.response?.data?.message;
        console.log("message_404", message_404);
        toast.error(message_404);
      } else if (error.response.status === 500) {
        const message_500 = error?.response?.data?.message;
        toast.error(message_500);
      } else {
        toast.error("something went wrong");
      }
    }
  };
  const handleDateconvert = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = `${getMonthName(
      date.getMonth()
    )} ${date.getDate()}, ${date.getFullYear()}`;
    console.log("formattedDate", formattedDate);
    return formattedDate;
  };
  const getMonthName = (monthIndex) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthIndex];
  };
  useEffect(() => {
    handleGetNewsData();
  }, []);

  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">News/Important Notice</h1>
            </div>
          </div>
          <div className="row">
            {getAllNewsData?.map((value, index) => {
              return (
                <>
                  <div className="col-md-4 col-12 my-3">
                    <div>
                      <div className="card new-cus-card">
                        <div className="blogimg">
                          <img
                            className
                            src={`https://backend.nileshdawande.in/images/${value?.ImageOne}`}
                            alt=""
                          />
                        </div>
                        <div className="card-body blog-card ps-0">
                          <div className="d-flex justify-content-start ">
                            <div className="pe-2">
                              <strong style={{ color: "grey" }}>
                                Posted on
                              </strong>
                            </div>
                            <div className="pe-2">
                              <p>
                                {/* <strong>Feb 02, 2024</strong> */}
                                <strong>
                                  {handleDateconvert(value?.createdAt)}
                                </strong>
                              </p>
                            </div>
                          </div>
                          <div className="text-start ">
                            <h6 className="mx-1 fw-bold">{value?.NewsTitle}</h6>
                          </div>
                          <div className="text-start ">
                            <p className="mx-1">{value?.NewsDesc}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            {/* <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1705478286562.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 17, 2024</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>
                        Decentrawood Glamour: A Peek into the Celebrity Villa
                        Extravaganza
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1705141927707.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 13, 2024</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>Deod: Your Ultimate Digital Wealth Engine</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1705063153852.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 12, 2024</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>
                        Beyond Borders: Navigating the Spiritual Realm in the
                        Digital Tapestry of Decentrawood's Virtual Ram Mandir
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1704976729875.png"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 11, 2024</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>
                        Decentrawood Gaming: Crafting Your Unique Digital
                        Universe
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1704974990248.png"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 10, 2024</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>
                        DecentraWood: Unleashing the Metaverse – A Digital
                        Wonderland of Possibilities
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 my-3">
              <div>
                <div className="card new-cus-card">
                  <div className="blogimg">
                    <img
                      className
                      src="https://backend.decentrawood.com/asset/getImages?pathName=BLOGS_IMAGE&imageName=blog-1704526421602.jpeg"
                      alt=""
                    />
                  </div>
                  <div className="card-body blog-card ps-0">
                    <div className="d-flex justify-content-start ">
                      <div className="pe-2">
                        <p>
                          <strong>Jan 20, 1970</strong>
                        </p>
                      </div>
                      <div className="pe-2">
                        <strong style={{ color: "grey" }}>EVENTS</strong>
                      </div>
                    </div>
                    <div className="text-start ">
                      <h6>
                        Welcoming 2024: A Year of Boundless Possibilities in
                        Decentrawood's Metaverse
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </Layout>
  );
};

export default NewsPageSection;
