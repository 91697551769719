import React from "react";
import Layout from "../Layout";
import "./styles.css";

const OurMissionPageSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Our Mission</h1>
            </div>
          </div>
        </div>
        <div className="about-container">
          {/* <div className="image-container">
            <img
              src="../assets/dashbordbanner.png"
              alt="Background"
              className="background-image"
            />
          </div> */}
          <div className="content main-containt">
            <h4 className="section-title">
              Our Mission at the NFSSC Fire and Safety Institute
            </h4>
            <p>
              In the realm of fire and safety, excellence is not just a goal;
              it's a necessity. At the NFSSC Fire and Safety Institute, we stand
              as beacons of knowledge and guardians of safety, dedicated to
              shaping the next generation of fire and safety professionals. Our
              mission is clear: to inspire, educate, and equip individuals with
              the skills and expertise needed to protect lives, property, and
              the environment.
            </p>
            <h4>Our Core Tenets:</h4>
            <p>
              Excellence in Education: We believe in providing top-tier
              education that goes beyond the textbooks. Our curriculum is
              meticulously crafted to blend theoretical knowledge with practical
              applications, ensuring that our students are not only well-versed
              in fire science and safety principles but also adept at applying
              them in real-world scenarios.
            </p>
            <p>
              Hands-On Training: Theory without practice is like a flame without
              oxygen—it flickers and fades. That's why we place a strong
              emphasis on hands-on training, offering state-of-the-art
              facilities and simulations where students can hone their
              firefighting, rescue, and emergency response skills under the
              guidance of seasoned professionals.
            </p>
            <h4>Innovation and Adaptation:</h4>
            <p>
              The landscape of fire and safety is ever-evolving, marked by new
              challenges and advancements. We embrace innovation as a
              cornerstone of our mission, encouraging research, experimentation,
              and the adoption of cutting-edge technologies to stay at the
              forefront of the field. Commitment to Safety Culture: Safety isn't
              just something we teach—it's ingrained in our DNA. We foster a
              culture of safety that permeates every aspect of our institute,
              from the way we conduct our training exercises to the protocols we
              follow in our facilities. Our goal is not just to produce
              competent professionals but to cultivate safety-conscious
              individuals who prioritize prevention and preparedness.
            </p>
            <h4>Our Mission in Action:</h4>
            <p>
              gniting Excellence: Our Mission at the NFSSC Fire and Safety
              Institute In the realm of fire and safety, excellence is not just
              a goal; it's a necessity. At the NFSSC Fire and Safety Institute,
              we stand as beacons of knowledge and guardians of safety,
              dedicated to shaping the next generation of fire and safety
              professionals. Our mission is clear: to inspire, educate, and
              equip individuals with the skills and expertise needed to protect
              lives, property, and the environment. Our Core Tenets: Excellence
              in Education: We believe in providing top-tier education that goes
              beyond the textbooks. Our curriculum is meticulously crafted to
              blend theoretical knowledge with practical applications, ensuring
              that our students are not only well-versed in fire science and
              safety principles but also adept at applying them in real-world
              scenarios. Hands-On Training: Theory without practice is like a
              flame without oxygen—it flickers and fades. That's why we place a
              strong emphasis on hands-on training, offering state-of-the-art
              facilities and simulations where students can hone their
              firefighting, rescue, and emergency response skills under the
              guidance of seasoned professionals. Innovation and Adaptation: The
              landscape of fire and safety is ever-evolving, marked by new
              challenges and advancements. We embrace innovation as a
              cornerstone of our mission, encouraging research, experimentation,
              and the adoption of cutting-edge technologies to stay at the
              forefront of the field. Commitment to Safety Culture: Safety isn't
              just something we teach—it's ingrained in our DNA. We foster a
              culture of safety that permeates every aspect of our institute,
              from the way we conduct our training exercises to the protocols we
              follow in our facilities. Our goal is not just to produce
              competent professionals but to cultivate safety-conscious
              individuals who prioritize prevention and preparedness. Our
            </p>
            <h4> Mission in Action: </h4>
            <p>
              1. Education for Impact: Through our comprehensive training
              programs, workshops, and seminars, we aim to empower individuals
              with the knowledge and skills needed to make a tangible difference
              in their communities. Whether it's through fire prevention
              initiatives, emergency response training, or occupational safety
              programs, we believe in equipping our graduates with the tools to
              save lives and protect property.
            </p>
            <p>
              Professional Development: Our commitment to excellence extends
              beyond graduation day. We offer ongoing professional development
              opportunities, advanced certification courses, and career
              advancement resources to support the continued growth and success
              of our alumni in the fire and safety profession.
            </p>

            <p>
              3. Research and Collaboration: We foster a culture of inquiry and
              collaboration, encouraging faculty, staff, and students to engage
              in research that pushes the boundaries of fire and safety science.
              Through partnerships with industry leaders, government agencies,
              and research institutions, we strive to drive innovation and shape
              the future of the field.
            </p>
            <p>
              4. Community Engagement: We believe that fire and safety are
              everyone's responsibility. That's why we actively engage with
              communities through outreach programs, public awareness campaigns,
              and partnerships with local organizations to promote fire
              prevention, emergency preparedness, and safety education.
            </p>
            <h4>Join Us in Making a Difference:</h4>
            <p>
              At the NFSSC Fire and Safety Institute, we are more than just
              educators—we are catalysts for change, champions of safety, and
              advocates for excellence. Join us in our mission to ignite
              excellence in fire and safety education, and together, let's make
              the world a safer place for generations to come.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default OurMissionPageSection;
