import React from "react";
import Layout from "../Layout";
import "./styles.css";
import AS1 from "../../../assets/images/Approve/Approve1.jpg";
import AS2 from "../../../assets/images/Approve/Approve2.jpeg";
import AS3 from "../../../assets/images/Approve/Approve3.jpeg";
import AS4 from "../../../assets/images/Approve/Approve4.jpeg";
import AS5 from "../../../assets/images/Approve/Approve5.png";
import AS6 from "../../../assets/images/Approve/Approve6.png";
import AS7 from "../../../assets/images/Approve/Approve7.png";

const ApproveSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Approval</h1>
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="row my-4">
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card approve-img p-2"
                  daPS-aos="flip-left"
                >
                  <img src={AS1} class="card-img-top " alt="..." />
                  {/* <h4 className="mt-2">Diploma In Sub-Fire Officer</h4> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS2} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Hazards & Risk</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS3} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Safety Management</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS4} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS5} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS6} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3 ">
                <div
                  className="card cus-card p-2 approve-img"
                  daPS-aos="flip-left"
                >
                  <img src={AS7} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ApproveSection;
