import React from "react";
import Layout from "../Layout";
import "./styles.css";
import TA1 from "../../../assets/images/ThrillingActivitie/TA1.jpg";
import TA2 from "../../../assets/images/ThrillingActivitie/TA2.jpg";
import TA3 from "../../../assets/images/ThrillingActivitie/TA3.jpg";
import TA4 from "../../../assets/images/ThrillingActivitie/TA4.jpeg";
import TA5 from "../../../assets/images/ThrillingActivitie/TA5.jpeg";
import TA6 from "../../../assets/images/ThrillingActivitie/TA6.jpeg";
import TA7 from "../../../assets/images/ThrillingActivitie/TA7.jpeg";
import TA8 from "../../../assets/images/ThrillingActivitie/TA8.jpeg";
import TA9 from "../../../assets/images/ThrillingActivitie/TA9.jpeg";
import TA10 from "../../../assets/images/ThrillingActivitie/TA10.jpeg";
import TA11 from "../../../assets/images/ThrillingActivitie/TA11.jpeg";
import TA12 from "../../../assets/images/ThrillingActivitie/TA12.jpeg";
import TA13 from "../../../assets/images/ThrillingActivitie/TA13.jpg";
import TA14 from "../../../assets/images/ThrillingActivitie/TA14.jpeg";
import TA15 from "../../../assets/images/ThrillingActivitie/TA15.jpg";
import TA16 from "../../../assets/images/ThrillingActivitie/TA16.jpg";
import TA17 from "../../../assets/images/ThrillingActivitie/TA17.jpeg";
import TA18 from "../../../assets/images/ThrillingActivitie/TA18.jpeg";
import TA19 from "../../../assets/images/ThrillingActivitie/TA19.jpeg";
import TA20 from "../../../assets/images/ThrillingActivitie/TA20.jpeg";
import TA21 from "../../../assets/images/ThrillingActivitie/TA21.jpeg";

const ThrillingActivitiesSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Thrilling Activities</h1>
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="row my-4">
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA1} class="card-img-top" alt="..." />
                  {/* <h4 className="mt-2">Diploma In Sub-Fire Officer</h4> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA2} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Hazards & Risk</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA3} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Safety Management</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA4} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA5} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fireman Tanning</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA6} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA7} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA8} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA9} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA10} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA11} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA12} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA13} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA14} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA15} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA16} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA17} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA18} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA19} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA20} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-thrilling p-2"
                  data-aos="flip-left"
                >
                  <img src={TA21} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ThrillingActivitiesSection;
