import React from "react";
import Layout from "../Layout";
import "./styles.css";
import PS1 from "../../../assets/images/ProjectStudent/PS1.jpg";
import PS2 from "../../../assets/images/ProjectStudent/PS2.jpg";
import PS3 from "../../../assets/images/ProjectStudent/PS3.jpg";
import PS4 from "../../../assets/images/ProjectStudent/PS4.jpg";
import PS5 from "../../../assets/images/ProjectStudent/PS5.jpg";
import PS6 from "../../../assets/images/ProjectStudent/PS6.jpeg";
import PS7 from "../../../assets/images/ProjectStudent/PS7.jpeg";
import PS8 from "../../../assets/images/ProjectStudent/PS8.jpeg";
import PS9 from "../../../assets/images/ProjectStudent/PS9.jpeg";
import PS10 from "../../../assets/images/ProjectStudent/PS10.jpeg";
import PS11 from "../../../assets/images/ProjectStudent/PS11.jpeg";
import PS12 from "../../../assets/images/ProjectStudent/PS12.jpeg";
import PS13 from "../../../assets/images/ProjectStudent/PS13.jpeg";
import PS14 from "../../../assets/images/ProjectStudent/PS14.jpeg";
import PS15 from "../../../assets/images/ProjectStudent/PS15.jpeg";
import PS16 from "../../../assets/images/ProjectStudent/PS16.jpeg";
import PS17 from "../../../assets/images/ProjectStudent/PS17.jpeg";
import PS18 from "../../../assets/images/ProjectStudent/PS18.jpg";
import PS19 from "../../../assets/images/ProjectStudent/PS19.jpeg";
import PS20 from "../../../assets/images/ProjectStudent/PS20.jpeg";
import PS21 from "../../../assets/images/ProjectStudent/PS21.jpeg";
const ProjectStudentSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Project by Students</h1>
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="row my-4">
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS1} class="card-img-top" alt="..." />
                  {/* <h4 className="mt-2">Diploma In Sub-Fire Officer</h4> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS2} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Hazards & Risk</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS3} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Safety Management</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-3">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS4} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fire & Explosion Hazards In Oil & Gas</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS5} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Fireman PSnning</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS6} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS7} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS8} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS9} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS10} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS11} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS12} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS13} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS14} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS15} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS16} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS17} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS18} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS19} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS20} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
              <div className="col-md-4 col-12 mb-2">
                <div
                  className="card cus-card-pstudent p-2"
                  daPS-aos="flip-left"
                >
                  <img src={PS21} class="card-img-top" alt="..." />
                  {/* <h2 className="mt-2">Diploma In Driver Cum Pump Operator</h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ProjectStudentSection;
