import React from "react";
import Layout from "../Layout";
import "./styles.css";
import About1 from "../../../assets/images/About1.png";
import About2 from "../../../assets/images/About2.png";
import About3 from "../../../assets/images/About3.png";
const AboutUsSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">About Us</h1>
            </div>
          </div>
        </div>

        <section className="bg-gray">
          <div className="container  py-4">
            <div className="row">
              <div className="col-12 col-md-6 order-1 order-md-0">
                <div className="content-box">
                  <h2 className="heading-txt">
                    About NFSSC Fire and Safety Institute
                  </h2>
                  <p className="mb-2">
                    Welcome to NFSSC Fire and Safety Institute, where safety is
                    paramount, excellence is nurtured, and futures are forged.
                    As a leading institution in fire and safety education, NFSSC
                    is dedicated to equipping individuals with the knowledge and
                    skills necessary to safeguard lives, property, and the
                    environment. Here's a glimpse into who we are and what we
                    stand for:
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-2">
                <div className="img-boxxx">
                  <img src={About1} className="img-fluidd w-75" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="gray-bg">
          <div className="container  py-4">
            <div className="row">
              <div className="col-12 col-md-6 mb-2">
                <div className="img-boxxx">
                  <img src={About2} className="img-fluid w-75" alt="" />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="content-box">
                  <h2 className="heading-txt">Our Legacy </h2>
                  <p className="mb-2">
                    Established with a vision to elevate standards in fire and
                    safety education, NFSSC Fire and Safety Institute has been a
                    beacon of knowledge and expertise for 10+years. Over the
                    years, we have honed our curriculum, refined our
                    methodologies, and evolved with the changing landscape of
                    safety practices to meet the needs of our students and the
                    industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gray">
          <div className="container  py-4">
            <div className="row">
              <div className="col-12 col-md-6 order-1 order-md-0">
                <div className="content-box">
                  <h2 className="heading-txt">Our Mission</h2>
                  <p className="mb-2">
                    At NFSSC, our mission is clear: to empower individuals with
                    the education and training needed to excel in the field of
                    fire prevention, emergency response, and occupational
                    safety. We are committed to fostering a culture of safety
                    consciousness, professionalism, and continuous learning
                    among our students and alumni.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-2">
                <div className="img-boxxx">
                  <img src={About3} className="img-fluid w-75" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="content main-containt">
            <h4>What Sets Us Apart:</h4>
            <p>
              1. Expert Faculty: Our faculty comprises seasoned professionals,
              industry experts, and academic scholars who bring a wealth of
              experience and expertise to the classroom. They are passionate
              about imparting knowledge, mentoring students, and shaping the
              next generation of safety leaders.
            </p>
            <p>
              2. Comprehensive Curriculum: Our curriculum is meticulously
              designed to cover all aspects of fire science, safety engineering,
              emergency management, and risk assessment. From foundational
              courses to specialized electives, we offer a diverse range of
              programs to cater to the diverse needs and interests of our
              students.
            </p>
            <p>
              3. Hands-On Training: We believe in learning by doing. That's why
              we provide ample opportunities for hands-on training, practical
              exercises, and simulations in our state-of-the-art facilities.
              From fire drills to rescue missions, students gain invaluable
              experience in a safe and controlled environment.
            </p>
            <p>
              4. Industry Connections: NFSSC maintains strong ties with industry
              partners, government agencies, and professional associations to
              stay abreast of industry trends, best practices, and emerging
              technologies. Through internships, guest lectures, and networking
              events, we provide students with exposure to real-world scenarios
              and career opportunities.
            </p>
            <h4>Our Commitment to Excellence:</h4>
            <p>
              Excellence is not just a goal; it's our standard. We are dedicated
              to upholding the highest standards of academic rigor, ethical
              conduct, and professional integrity in everything we do. Our
              commitment to excellence extends beyond the classroom to our
              support services, facilities, and student experience.
            </p>
            <h4>Join Us in Pursuit of Safety:</h4>
            <p>
              Whether you're a prospective student, a working professional
              seeking to enhance your skills, or an organization looking to
              invest in the training of your personnel, NFSSC Fire and Safety
              Institute welcomes you to join us on our journey toward a safer,
              more resilient future. Together, let's make safety our priority
              and excellence our legacy.
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};
export default AboutUsSection;
