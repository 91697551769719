import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./component/Pages/LandingPage/index";
import AboutUsPage from "./component/Pages/AboutUsPage";
import ContactUsPage from "./component/Pages/ContactUsPage";
import NfsscProgramPage from "./component/Pages/NfsscProgramPage";
import NcvteProgramPage from "./component/Pages/NcvteProgramPage";
import UniversityProgramPage from "./component/Pages/UniversityProgramPage";
import ThrillingActivitiePage from "./component/Pages/ThrillingActivitiePage";
import ProjectStudentPage from "./component/Pages/ProjectStudentPage";
import AwarenessProgramPage from "./component/Pages/AwarenessProgramPage";
import AboutPlacementPage from "./component/Pages/AboutPlacementPage";
import OurQualitiesPage from "./component/Pages/OurQualitiesPage";
import OurCentersPage from "./component/Pages/OurCentersPage";
import OurTeamPage from "./component/Pages/OurTeamPage";
import HowtoApplyPage from "./component/Pages/HowtoApplyPage";
import NewsPage from "./component/Pages/NewsPage";
import ResultPage from "./component/Pages/ResultPage";
import ApprovePage from "./component/Pages/ApprovePage";
import OurMissionPage from "./component/Pages/OurMissionPage";
import OurVisionPage from "./component/Pages/OurVisionPage";
import PlacementPage from "./component/Pages/PlacementPage";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/nfssc-program" element={<NfsscProgramPage />} />
        <Route
          path="/university-programme"
          element={<UniversityProgramPage />}
        />
        <Route path="/ncvte-programs" element={<NcvteProgramPage />} />
        <Route
          path="/thrilling-activities"
          element={<ThrillingActivitiePage />}
        />
        <Route path="/awareness-programs" element={<AwarenessProgramPage />} />
        <Route path="/project-by-students" element={<ProjectStudentPage />} />
        <Route path="/about-placement" element={<AboutPlacementPage />} />
        <Route path="/our-qualities" element={<OurQualitiesPage />} />
        <Route path="/our-centers" element={<OurCentersPage />} />
        <Route path="/our-team" element={<OurTeamPage />} />
        <Route path="/how-to-apply" element={<HowtoApplyPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/approve" element={<ApprovePage />} />
        <Route path="/ourmission" element={<OurMissionPage />} />
        <Route path="/ourvision" element={<OurVisionPage />} />
        <Route path="/placement" element={<PlacementPage />} />
      </Routes>
    </>
  );
}

export default App;
