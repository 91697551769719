import React from "react";
import Layout from "../Layout";
import "./styles.css";

const OurQualitiesSection = () => {
  const data = [
    {
      Title: "100 % Placement Record",
    },
    {
      Title: "100 % support for International Placement",
    },
    {
      Title: "Independent Placement Department",
    },
    {
      Title: "Tie-up more than 200 Companies For Placement",
    },
    {
      Title: "Having big network of 100+ Centre’s across India",
    },
    {
      Title:
        "NFSSC is having dedicated training team of professionals comprising Defense Officers, Fire Scientists / Engineers and Fire Experts who boast of knowing Fire-Safety from “Womb to Tomb",
    },
    {
      Title: "Internationally Recognized Certification",
    },
    {
      Title: "National Certification in Modular Employable Skills",
    },
    {
      Title: "UGC/DEC/AICTE/Govt. HRD Recognized University Certification",
    },
    {
      Title: "Govt. ITI-MSBVE Certification",
    },
    {
      Title: "Conduct OSHA’S and NEBOSH Training Programme",
    },
    {
      Title: "First Aid Certification",
    },
    {
      Title: "Provide more and more study material.",
    },
    {
      Title: "Fire-Safety Video Classes",
    },
    {
      Title: "Industrial Visits",
    },
    {
      Title: "Attachment with local fire station and industries for practical",
    },
    {
      Title:
        "100 % support for International PlacementConduct Survival and Adventure camps for students every year",
    },
    {
      Title: "Strong Administrative policies and mechanisms",
    },
  ];
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Our Qualities</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="accordion-container">
                {data.map((value, key) => {
                  return (
                    <>
                      <div className="accordion-item">
                        <button className="accordion-header">
                          {value?.Title} <span className="icon">+</span>
                        </button>
                        <div className="accordion-content">
                          <p>Answer to question 1...</p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default OurQualitiesSection;
