import React from "react";
import Layout from "../Layout";
import "./styles.css";
const UniversityProgramPageSection = () => {
  return (
    <Layout>
      <div className="contact-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="contact-title">University Program</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="details-box">
                <p>
                  UGC, AICTE, DEB, Ministry of HRD, Govt. of India Approved
                  University Courses
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>1</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Diploma in Fire-Safety and Hazards Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>2</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Fire and Safety Engineering</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSE</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>3</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Industrial Safety</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>
                            English/Hindi 10th + 2 (Sci)/2 Year ITI or
                            Equivalent
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>4</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Fire Sub Officer</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSO</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th + 2 Or Equivalent</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>5</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>6</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>
                            {" "}
                            PG Diploma in Fire Safety and Hazards Management
                          </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>PGDFSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi Any Graduation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>7</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>PG Diploma in Industrial Safety</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>PGDIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi B.Sc./Diploma/B.E./B.Tech</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>8</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> PG Diploma Health Safety and Environment</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>PGDHSE</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi B.Sc./Diploma/B.E./B.Tech</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>9</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Advance Diploma in Industrial Safey</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>ADIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>
                            {" "}
                            English/Hindi B.Sc./Diploma/B.E./B.Tech with 1 Yr.
                            Industrial Exp.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>10</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Advance Diploma in Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>ADSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi Any Graduation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>11</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>
                            {" "}
                            BSc. Fire-Safety and Hazard Management (Lateral
                            Entry)
                          </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>BSc.-FSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year/ 2 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>
                            English/Hindi HSc. Sci Or Equiv + 1/2 Yr. Fire &
                            Safety Dip.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>12</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> MBA Fire and Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>MBA-FSM </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>2 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi Any Graduation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-programtwo-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>13</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> B.Tech in Fire</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>B.Tech-F</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4> 4 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>
                            English/Hindi 10th + 2 (Sci)/2 Year ITI or
                            Equivalent
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default UniversityProgramPageSection;
