import React from "react";
import Layout from "../Layout";
import "./styles.css";
const NcvtePageSection = () => {
  return (
    <Layout>
      <div className="nfssc-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="nfssc-title">NFSSC Program</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="details-box">
                <p>
                  NFSSC Program Fire Engineering and Industrial Safety as a
                  career option is fast catching up with the students in India.
                  Whereas in abroad Fire Engineering and Industrial Safety is an
                  integral part of most of university programs Introduction of
                  Fire Engineering and Industrial Safety courses is in fact an
                  index of industrial growth.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="row my-2">
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>1</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Diploma in Fire-Safety and Hazard</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>2</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Diploma in Fire Sub Officer</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSO</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th + 2, ITI Or Equivalent</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>3</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Assistant Fire Sub Officer</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DAFSO</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th + 2, ITI Or Equivalent</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>4</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Industrial Safet</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>
                            English/Hindi 10th + 2 (Science), ITI Or Equivalent
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>5</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>6</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Diploma in Fireman Training</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFT</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>7</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Diploma in Driver cum Pump Operator</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DDCPO</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>8</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>
                            {" "}
                            Diploma in Fire-Safety and Security Management
                          </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>DFSSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>9</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Advance Diploma in Industrial Safety</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>ADIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi B.Sc./Diploma/B.E./B.Tech</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>10</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Advance Diploma in Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>ADSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi Any Graduation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>11</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> PG Diploma in Fire-Safety and Hazard</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>PGDFSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4> English/Hindi Any Graduation</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>12</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> PG Diploma in Industrial Safety</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>PGDIS </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>1 Year</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi B.Sc. / Diploma / B.E. / B.Tech</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>13</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>
                            {" "}
                            Certificate in Fire Safety and Hazard Management
                          </h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CFSHM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>14</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Certificate in Industrial Safety</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CIS</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4>6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>15</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Certificate in Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4> 6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>16</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Certificate in Fireman Training</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CFT</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4> 6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>17</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4> Certificate in Driver Cum Pump Operator</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CDCPO</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4> 6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12 my-3">
                  <div className="card maincus-card">
                    <div className="card-body cus-program-card">
                      <div className="cus-sepret">
                        <div>
                          <h3>Sr.No</h3>
                        </div>
                        <div>
                          <h4>18</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Name</h3>
                        </div>
                        <div>
                          <h4>Certificate in Fire Safety Management</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Course Code</h3>
                        </div>
                        <div>
                          <h4>CFSSM</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Duration Medium</h3>
                        </div>
                        <div>
                          <h4> 6 Month</h4>
                        </div>
                      </div>
                      <div className="cus-sepret">
                        <div>
                          <h3>Eligibility</h3>
                        </div>
                        <div>
                          <h4>English/Hindi 10th Class or Above</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default NcvtePageSection;
