import React from "react";
import Layout from "../Layout";
import "./styles.css";

const OurVisionPageSection = () => {
  return (
    <Layout>
      <div className="about-container">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              {" "}
              <h1 className="about-title">Our Vision</h1>
            </div>
          </div>
        </div>
        <div className="about-container">
          {/* <div className="image-container">
            <img
              src="../assets/dashbordbanner.png"
              alt="Background"
              className="background-image"
            />
          </div> */}
          <div className="content main-containt">
            <h4 className="section-title">
              NFSSC Fire and Safety Institute: Shaping Tomorrow's Fire and
              Safety Leaders
            </h4>
            <p>
              At NFSSC Fire and Safety Institute, our vision extends far beyond
              the walls of our classrooms. We aspire to be a beacon of
              innovation, knowledge, and leadership in the realm of fire and
              safety education. Our vision is not just about imagining a safer
              world; it's about actively working towards it, one student, one
              program, and one community at a time.
            </p>
            <h4>Our Visionary Outlook:</h4>
            <p>
              1. Global Influence: We envision NFSSC Fire and Safety Institute
              as a global leader in fire and safety education and research.
              Through collaboration with international partners, engagement with
              industry leaders, and participation in global initiatives, we aim
              to influence and shape safety practices on a global scale.
            </p>
            <p>
              2. Cutting-Edge Research: Our vision includes establishing a
              research center dedicated to advancing the science and technology
              of fire prevention, emergency response, and safety engineering.
              Through interdisciplinary research projects, innovative
              experiments, and technology development, we seek to push the
              boundaries of knowledge and drive progress in the field.
            </p>
            <p>
              3. Innovative Education: We aspire to redefine fire and safety
              education for the future. Our vision includes developing dynamic,
              experiential learning programs that integrate theory with
              practical skills, simulation-based training, and real-world
              scenarios. By embracing emerging technologies and pedagogical
              approaches, we aim to prepare students for the complexities of
              modern safety challenges.
            </p>
            <p>
              {" "}
              4. Community Impact: We believe in the power of education and
              outreach to create lasting change in communities. Our vision
              includes actively engaging with local communities through outreach
              programs, public awareness campaigns, and partnerships with local
              organizations. By empowering individuals and organizations with
              the knowledge and skills to prevent accidents, mitigate risks, and
              respond effectively to emergencies, we aim to create safer, more
              resilient communities.{" "}
            </p>

            <h4>Realizing Our Vision:</h4>
            <p>
              1. Excellence in Education: Our vision is to produce graduates who
              are not only well-versed in safety principles but also critical
              thinkers, problem solvers, and leaders in their field. Through
              rigorous academics, hands-on training, and mentorship
              opportunities, we aim to cultivate a new generation of safety
              professionals who are equipped to address the evolving challenges
              of the future.
            </p>
            <p>
              2. Impactful Research: We envision our research initiatives making
              a tangible impact on safety practices, policies, and technologies.
              Whether it's developing innovative firefighting techniques,
              designing resilient infrastructure, or enhancing emergency
              communication systems, our research endeavors are guided by a
              commitment to improving safety outcomes and saving lives.
            </p>
            <p>
              3. Global Collaboration: Our vision includes fostering
              collaborations with researchers, practitioners, and stakeholders
              around the world. By leveraging the collective expertise and
              resources of diverse partners, we aim to accelerate progress,
              promote innovation, and address complex safety challenges that
              transcend borders and disciplines.
            </p>
            <p>
              4. Community Engagement: We envision our institute as a hub of
              community engagement, where students, faculty, and staff actively
              contribute to the well-being of local communities. Through
              outreach programs, volunteer initiatives, and partnerships with
              local organizations, we aim to make a positive impact on the
              communities we serve and foster a culture of safety and
              preparedness.
            </p>

            <h4>Join Us in Shaping the Future:</h4>
            <p>
              At NFSSC Fire and Safety Institute, we are driven by a shared
              vision of creating a safer, more resilient world. Whether you're a
              student, a researcher, a practitioner, or a community member, we
              invite you to join us in our mission to advance safety, promote
              innovation, and create positive change. Together, we can build a
              future where everyone can live, work, and thrive in environments
              that are safe, secure, and sustainable.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default OurVisionPageSection;
