import React from "react";
import Header from "./header";
import Footer from "./footer";
import { Helmet } from "react-helmet";

const Layout = (props) => {
  const { children, title, description, keywords, author } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};
Layout.defaultProps = {
  title: "NFSSC - National Fire & Safety Service College Nagpur",
  description: "fire & safety service project",
  keywords: "mern,react,node,mongodb",
  author: "Mayur & Nilesh",
};
export default Layout;
